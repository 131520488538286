import { imageFactory } from "@/lib/print/factory";
import information from "./information";

const menu = " - Restaurant Menu Design";
const poster = " - Restaurant Wall Poster";

const meta = [
  { alt: "Bar Speciality Drinks" + menu, info: 0 },
  { alt: "Restaurant Drink Advertisement" + poster, info: 1 },
  { alt: "Limited Time Items" + poster, info: 1 },
  { alt: "Food & Drink Samples" + poster, info: 1 }
];

export const title = "food & beverage";
export const images = imageFactory(meta, title, information);
