export default [
  {
    title: `8.5" x 11" Factsheet`,
    subtitle: null,
    lists: [
      {
        title: "Includes:",
        bullets: [
          "Full color, two-sided, with bleed",
          "Professional design and copywriting",
          "Multi-property and multi-brand designs available",
          "Quality 100 lb gloss book with AQ coating standard"
        ]
      },
      {
        title: "Premium stock upgrades options:",
        bullets: [
          "100 lb Gloss Cover",
          "100 lb Dull Cover with Matte Finish",
          "80 lb Dull Text with Matte Finish"
        ]
      }
    ],
    cost: "Starting at $473 for 250"
  }
];
