<template>
  <div id="print-info">
    <p class="text normal bold">{{ info.title }}</p>
    <p class="text normal" v-if="info.subtitle">{{ info.subtitle }}</p>
    <div class="list" v-for="(list, index) in info.lists" :key="index">
      <p class="text normal bold list-title">{{ list.title }}</p>
      <ul class="list">
        <li class="text normal" v-for="(bullet, index) in list.bullets" :key="index">
          {{ bullet }}
        </li>
      </ul>
    </div>
    <p class="text normal bold" v-if="info.afterCost">{{ info.afterCost }}</p>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
#print-info {
  padding: 0 1rem;
}

#print-info > p:first-of-type {
  margin-top: 0;
}

.text {
  color: #314657;
  font-size: 1vw;
}

.bold {
  font-weight: bold;
}

.list-title {
  margin-bottom: 0;
}

.list {
  margin-top: 0;
  list-style-type: none;
  padding: 0;
}

.list > li {
  padding-left: 0.5rem;
}

@media screen and (max-width: 1366px) {
  #print-info {
    padding-top: 3rem;
  }
  .text {
    font-size: 1.5vw;
  }
}

@media screen and (max-width: 811px) {
  #print-info {
    padding-top: 1rem;
  }
  .text {
    font-size: 3vw;
  }
}
</style>
