import { imageFactory } from "@/lib/print/factory";
import information from "./information";

const rackcard = " - Branded - Rack Card";

const meta = [
  { alt: "Renovation Announcement" + rackcard, info: 0 },
  { alt: "Property Services" + rackcard, info: 0 },
  { alt: "Property Features" + rackcard, info: 0 },
  { alt: "Hotel Features" + rackcard, info: 0 },
  { alt: "Evening Social Information" + rackcard, info: 0 },
  { alt: "Sports Advertising" + rackcard, info: 0 },
  { alt: "Suite Amenities" + rackcard, info: 0 },
  { alt: "Wedding Planning Information" + rackcard, info: 0 }
];

export const title = "rack cards";
export const images = imageFactory(meta, title, information);
