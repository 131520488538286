export default [
  {
    title: `X-Frame Banner Stands`
  },
  {
    title: "Wall Mount and Rigid Posters"
  },
  {
    title: "Vehicle Wraps"
  },
  {
    title: "In-room Signage"
  },
  {
    title: "Top of Table Signage"
  }
];
