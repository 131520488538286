<template>
  <div class="print__btf">
    <img class="background__image" v-lazy="background" alt="">
    <ButtonContainer :buttons="buttons" />
    <transition name="fade" mode="out-in">
      <router-view v-on:contact="contact($event)"/>
    </transition>
    <!-- <p>Give your guests, clients, and potential partners something meaningful to hold to. Nothing can deliver tactile fulfillment and physical presence like vibrant inks on unique paper. That is a potent tool that leaves a long lasting impression.  Our graphic design team understands the impact of high-quality print collateral.</p> -->
    <!-- <p>All Things Hospitality will coordinate with you from conceptualization through delivery. We provide polished business cards, eye-catching signage, sleek rack cards and more. Take a look at the examples listed above and feel free to [contact us] if you have any questions or unique ideas.</p> -->
  </div>
</template>

<script>
import buttons from "@/lib/print/buttons";
import ButtonContainer from "@/components/print/below/buttons/ButtonContainer.vue";

export default {
  components: { ButtonContainer },
  data() {
    return {
      selection: null,
      background: {
        src: require("@/assets/print/background/nice/1.jpg"),
        loading: require("@/assets/print/background/lazy/1.jpg")
      },
      buttons
    };
  },
  methods: {
    contact(spec) {
      this.$emit("contact", spec);
    }
  }
};
</script>

<style scoped>
.print__btf {
  position: relative;
  overflow: hidden;
  background: linear-gradient(#e4e4e4 0.5%, transparent, #e4e4e4);
}
.background__image {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.55;
  filter: blur(8px);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.25s;
}
</style>
