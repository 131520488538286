import { imageFactory } from "@/lib/print/factory";
import information from "./information";

const branded = " - Branded";
const banner = branded + " - X-Frame Banner";
const poster = branded + " - Wall Mount Poster";
const wrap = branded + " - Vehicle Wrap";
const inroom = branded + " - Small In Room Signage";
const table = branded + " - Table Top Pop Up";

const meta = [
  { alt: "Meeting Space Information" + banner, info: 0 },
  { alt: "Restaurant Specials" + poster, info: 1 },
  { alt: "Hotel Shuttle" + wrap, info: 2 },
  { alt: "Construction Notice" + poster, info: 1 },
  { alt: "Restaurant Samples" + poster, info: 1 },
  { alt: "Room Information" + inroom, info: 3 },
  { alt: "Renovation Sneak Peek" + poster, info: 1 },
  { alt: "Wedding Planning Information" + banner, info: 0 },
  { alt: "Hotel Information" + inroom, info: 3 },
  { alt: "Restaurant Information" + poster, info: 1 },
  { alt: "Comedy Club Specials" + table, info: 4 }
];

export const title = "signage";
export const images = imageFactory(meta, title, information);
