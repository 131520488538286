<template>
<div id="print-carousel">
  <div class="title__wrapper" >
    <h3 class="title normal uppercase">{{ title }}</h3>
  </div>
  <div class="feature">
    <div class="carousel">
      <agile ref="carousel" v-on:before-change="newSlide($event)" :arrows="false">
        <img class="container" v-for="(image, index) in images" :key="index" v-lazy="image.thumb" :alt="image.alt">      
      </agile>
    </div>
    <div class="content">          
      <Information :info="images[currentIndex].information"/>
      <button class="standout uppercase highlight" @click="contact">Order</button>
    </div>
  </div>
  
</div>
  
</template>

<script>
import Information from "@/components/print/below/information/PrintInformation.vue";

export default {
  props: ["title", "images"],
  components: { Information },
  methods: {
    newSlide({ goToNext }) {
      this.currentIndex = goToNext;
    },
    contact() {
      this.$emit("contact", this.title);
    }
  },
  data() {
    return { currentIndex: 0 };
  }
};
</script>

<style>
#print-carousel {
  width: 90%;
  margin: 0 auto;
}

#print-carousel .agile {
  position: relative;
}

#print-carousel .agile__slide {
  width: 100%;
  height: auto;
}

#print-carousel .agile__dots {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 1024px) {
  #print-carousel .agile__dots {
    /* bottom: unset; */
    /* left: unset; */
    /* right: 2.5%; */
    /* top: 10%; */
    /* transform: translateY(-5%); */
    /* flex-direction: column; */
  }

  #print-carousel .agile__dot:last-of-type {
    display: block;
  }

  #print-carousel .agile__dot {
    margin: 0.25rem;
  }

  #print-carousel .agile__dot > button {
    width: 0.75rem;
    height: 0.75rem;
  }
}
</style>

<style scoped>
.title {
  font-size: 4vw;
  color: var(--ath-blue);
  margin-bottom: 1rem;
}

.feature {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.carousel {
  width: 70%;
}

.content {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

button {
  background: none;
  border: none;
  border-left: 2px solid var(--ath-blue);
  border-right: 2px solid var(--ath-blue);
}

@media screen and (max-width: 811px) {
  .carousel,
  .content {
    width: 100%;
  }
}
</style>
