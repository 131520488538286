export default [
  {
    key: "fb",
    text: "food & beverage",
    to: "/print/food-and-beverage",
    image: {
      alt: "Food & Beverage Selection Button",
      src: require("@/assets/print/buttons/nice/1.jpg"),
      loading: require("@/assets/print/buttons/lazy/1.jpg")
    }
  },
  {
    key: "fs",
    text: "factsheets",
    to: "/print/factsheets",
    image: {
      alt: "Fact Sheet Selection Button",
      src: require("@/assets/print/buttons/nice/2.jpg"),
      loading: require("@/assets/print/buttons/lazy/2.jpg")
    }
  },
  {
    key: "rc",
    text: "rack cards",
    to: "/print/rackcards",
    image: {
      alt: "Rackcards Selection Button",
      src: require("@/assets/print/buttons/nice/3.jpg"),
      loading: require("@/assets/print/buttons/lazy/3.jpg")
    }
  },
  {
    key: "rn",
    text: "renovations",
    to: "/print/renovations",
    image: {
      alt: "Renovations Selection Button",
      src: require("@/assets/print/buttons/nice/4.jpg"),
      loading: require("@/assets/print/buttons/lazy/4.jpg")
    }
  },
  {
    key: "sg",
    text: "signage",
    to: "/print/signage",
    image: {
      alt: "Signage Selection Button",
      src: require("@/assets/print/buttons/nice/5.jpg"),
      loading: require("@/assets/print/buttons/lazy/5.jpg")
    }
  },
  {
    key: "cr",
    text: "cards",
    to: "/print/cards",
    image: {
      alt: "Cards Selection Button",
      src: require("@/assets/print/buttons/nice/6.jpg"),
      loading: require("@/assets/print/buttons/lazy/6.jpg")
    }
  }
];
