import { imageFactory } from "@/lib/print/factory";
import information from "./information";

const branded = " - Branded";
const small = " - 5x7 Renovation Advertising Card";
const flyer = " - Renovation Factsheet Flyer";
const poster = " - Renovation Announcement Poster";

const meta = [
  { alt: "Renovation Unveiling" + small, info: 0 },
  { alt: "Grand Opening" + branded + flyer, info: 1 },
  { alt: "Red Carpet Premiere" + small, info: 0 },
  { alt: "Sneak Peek" + branded + poster, info: 2 },
  { alt: "Construction Notice" + poster, info: 2 },
  { alt: "Renovation Information" + branded + small, info: 0 }
];

export const title = "renovations";
export const images = imageFactory(meta, title, information);
