<template>
<div id="rackcards">
  <PrintCarousel  v-if="underMedLarge" :title="title" :images="images" v-on:contact="contact($event)"/>
  <PrintInteractive :numRows="numRows" :sizeBuffer="sizeBuffer" v-else :title="title" :images="images" v-on:contact="contact($event)"/>
</div>
  
</template>

<script>
import ScreenSize from "@/mixins/screen-size";
import { title, images } from "@/lib/print/showcase/rackcards";
import PrintCarousel from "@/components/print/below/showcase/Carousel.vue";
import PrintInteractive from "@/components/print/below/showcase/Interactive.vue";

export default {
  mixins: [ScreenSize],
  components: {
    PrintInteractive,
    PrintCarousel
  },
  data() {
    return {
      title,
      images,
      numRows: 3,
      sizeBuffer: 7.5
    };
  },
  methods: {
    contact(spec) {
      this.$emit("contact", spec);
    }
  },
  metaInfo: {
    title: "Hotel Rack Cards | High Impact Graphic Design",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Rack cards are fantastic for areas that enjoy significant foot traffic. Our team creates high-impact graphic designs and provide professional copywriting services. We will ensure that your sales message ends up in the right hands."
      }
    ],
    link: [
      {
        vmid: "canonical",
        rel: "canonical",
        href: "https://allthingshospitality.com/print/rackcards"
      }
    ]
  }
};
</script>
