export function imageFactory(imageArr, imgDir, information) {
  return imageArr.map((el, i) => ({
    ...el,
    information: information[el.info],
    thumb: getURL(imgDir, "thumb", i + 1),
    zoom: getURL(imgDir, "zoom", i + 1)
  }));
}

export function basicSchema(
  name = "Card Sized Print Collateral",
  description = "High quality print collateral & graphic design",
  category = "Print Collateral"
) {
  return {
    "@type": "Service",
    name,
    description,
    category,
    brand: {
      "@type": "Brand",
      name: "All Things Hospitality"
    }
  };
}

export function schemaFactory(schemaArr, imgDir) {
  const schemaWithImg = schemaArr.map((el, i) => ({
    ...el,
    image: getURL(imgDir, "thumb", i + 1).src
  }));

  return {
    "@context": "http://schema.org",
    "@graph": schemaWithImg
  };
}

function getURL(dir, type, img) {
  return {
    src: `../../img/print/showcase/${dir}/${type}/n/${img}.jpg`,
    loading: `../../img/print/showcase/${dir}/${type}/l/${img}.jpg`
  };
}

// export default function(obj, imgDir, information, schema) {
//   return obj.map((el, i) => ({
//     ...el,
//     information: information[el.info],
//     thumb: getURL(imgDir, "thumb", i + 1),
//     zoom: getURL(imgDir, "zoom", i + 1),
//     schema: {
//       ...schema[i],
//       image: getURL(imgDir, "thumb", i + 1).src
//     }
//   }));

//   function getURL(dir, type, img) {
//     return {
//       src: `../../img/print/showcase/${dir}/${type}/n/${img}.jpg`,
//       loading: `../../img/print/showcase/${dir}/${type}/l/${img}.jpg`
//     };
//   }
// }
