import { imageFactory, schemaFactory } from "@/lib/print/factory";
import information from "./information";
import basicSchema from "./schema";

const branded = " - Branded";
const keycard = " - Hotel Keycard Insert";
const social = " - Social Media Card";
const business = " - Personal Business Cards";

const meta = [
  { alt: "Restaurant Discount" + keycard, info: 0 },
  { alt: "Renovation Information" + keycard, info: 1 },
  { alt: "Social Media Advertising" + social, info: 2 },
  { alt: "Hotel Information" + branded + business, info: 3 },
  { alt: "Contact Information" + branded + business, info: 3 },
  { alt: "Fitness Center Information" + keycard, info: 0 },
  { alt: "Guest Review Generation" + social, info: 2 },
  { alt: "Restaurant Information" + social, info: 2 }
];

export const title = "cards";
export const images = imageFactory(meta, title, information);
export const schemaData = schemaFactory(basicSchema, title);
