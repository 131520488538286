<template>
  <div class="print__showcase">
    <div class="left">
      <p v-for="(letter, index) in title" :key="index" class="text__letter">
        {{ letter }}
      </p>
    </div>
    <div class="center">
      <div
        ref="image__wrapper"
        class="showcase__image__wrapper"
        v-for="(image, index) in images"
        :key="image.alt"
        @mouseenter="hover(index)"
      >
        <div class="image-interactable">
          <!-- <button class="standout uppercase highlight" @click="contact">Order</button> -->
          <a
            href="https://creator.zohopublic.com/jerideatherage1/product-catalog/report-perma/All_Products/hAGAPnzUet3pXCxDZ68AAkXtqj3C3hZqVXrswR1gYOevh1M5pAE8PT63NZESRV5wJCmX1kQQ5wBwwg2qSmeAhNJ25FW2B8CtMw1q"
            class="standout uppercase highlight"
            target="_blank"
            >Order</a
          >
          <button class="standout uppercase highlight" @click="zoomOpen(index)">
            Zoom
          </button>
        </div>
        <img v-lazy="image.thumb" :alt="image.alt" class="showcase__image" />
      </div>
    </div>
    <div class="right" :class="{ top: currentHover < 0 }">
      <Information
        :info="currentHover >= 0 ? images[currentHover].information : basic"
      />
    </div>
    <Zoom :zoom="zoom" :images="images" v-on:close-zoom="zoomClose" />
  </div>
</template>

<script>
import ScreenSize from "@/mixins/screen-size";
import Information from "@/components/print/below/information/PrintInformation.vue";
import Zoom from "@/components/print/below/showcase/Zoom.vue";

export default {
  mixins: [ScreenSize],
  data() {
    return {
      currentHover: -1,
      basic: {
        title: `Hover Over An Item For More Info`
      },
      zoom: {
        showing: false,
        position: 0
      }
    };
  },
  components: {
    Information,
    Zoom
  },
  props: {
    numRows: {
      type: Number,
      required: false,
      default: 2
    },
    sizeBuffer: {
      type: Number,
      required: false,
      default: 5
    },
    title: {
      type: String,
      required: true
    },
    images: {
      type: Array,
      required: true
    },
    info: Object
  },
  mounted() {
    this.adjustFlexBasis();
  },
  methods: {
    adjustFlexBasis() {
      const num = 100 / (this.images.length / this.numRows) - this.sizeBuffer;
      return this.$refs.image__wrapper.forEach(
        wrapper => (wrapper.style.flexBasis = `${num}%`)
      );
    },
    hover(index) {
      this.currentHover = index;
    },
    zoomOpen(index) {
      this.zoom.showing = true;
      this.zoom.position = index;
    },
    zoomClose() {
      this.zoom.showing = false;
    },
    contact() {
      this.$emit("contact", this.title);
    }
  }
};
</script>

<style scoped>
.print__showcase {
  --letter-height: 5vh;
  width: 90%;
  margin: 5rem 5% 5rem 5%;
  display: flex;
}

.left {
  flex-basis: 5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.text__letter {
  min-height: var(--letter-height);
  margin: 0;
  font-size: var(--letter-height);
  font-family: var(--font-normal);
  text-transform: uppercase;
  color: #314657;
}

.center {
  flex-basis: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.showcase__image__wrapper {
  margin: 1rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 1;
  flex-grow: 1;
}

.showcase__image {
  display: block;
  width: 100%;
  height: 100%;
}
.right.top {
  align-items: flex-start;
}
.right {
  position: sticky;
  flex-basis: 25%;
  display: flex;
  align-items: center;
}

.title__wrapper {
  margin: 0 0 2.5rem 0;
  flex-basis: 100%;
  display: flex;
}
.title {
  font-size: 9vw;
  color: #314657;
  margin: 0 auto;
}

.image-interactable {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  background: rgba(49, 70, 87, 0.5);
  transform: translateX(100%);
  transition: transform 0.3s;
}

.image-interactable > button,
.image-interactable > a {
  background: rgba(49, 70, 87, 0.75);
  border-radius: 1rem;
  padding: 0.25rem 0.75rem;
  border: none;
  font-size: 1.5vw;
  margin-bottom: 1.25rem;
}

.showcase__image__wrapper:hover > .image-interactable {
  transform: translateX(0);
}

a.standout.uppercase.highlight {
  text-decoration: none;
}
</style>
