<template>
  <div id="zoom-modal" v-if="zoom.showing">
    <div class="background" @click="$emit('close-zoom')"></div>
    <div class="content">
        <agile
        :fade="true"
        :showArrows="true"
        :showDots="true"
        :initial-slide="zoom.position"
      >
        <img v-for="(image, index) in images" :key="index" v-lazy="image.zoom" :alt="image.alt">
      </agile>
    </div>
    
  </div>
</template>

<script>
export default {
  props: {
    zoom: {
      type: Object,
      required: true
    },
    images: {
      type: Array,
      required: true
    }
  }
};
</script>


<style>
#zoom-modal {
  z-index: 51;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

#zoom-modal > .background {
  position: absolute;
  z-index: 52;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
}

#zoom-modal > .content {
  z-index: 53;
  width: 55%;
}

#zoom-modal > .content > .agile {
  width: 100%;
  position: relative;
  /* margin: 0 auto; */
}

#zoom-modal > .content > .agile > .agile__list > .agile__track > .agile__slide {
  width: 100%;
  height: auto;
}

#zoom-modal > .content > .agile > .agile__arrow {
  position: absolute;
  top: 50%;

  background: transparent;
  border: none;

  fill: white;
}

#zoom-modal > .content > .agile > .agile__arrow > svg {
  width: 2.25rem;
  height: 2.25rem;
}

#zoom-modal > .content > .agile > .agile__arrow.agile__arrow--prev {
  left: -20%;
}

#zoom-modal > .content > .agile > .agile__arrow.agile__arrow--next {
  right: -20%;
}
</style>
