export default [
  {
    title: `5" x 7" Renovation/Relaunch`,
    subtitle: "Custom sizes available",
    lists: [
      {
        title: "Includes:",
        bullets: [
          "Full color, two-sided, with bleed",
          "Professional design and copywriting",
          "14pt gloss or uncoated cardstock standard",
          "Quantaties as low as 100"
        ]
      }
    ],
    cost: "Starting at $372 for 250",
    afterCost:
      "Grab their attention with full color custom envelopes. Contact us for pricing"
  },
  {
    title: `8.5" x 11" Flyers`,
    lists: [
      {
        title: "Includes:",
        bullets: [
          "Full color, two-sided, with bleed",
          "Professional design and copywriting",
          "Quality 100lb gloss book with AQ coating standard"
        ]
      },
      {
        title: "Premium stock upgrade options:",
        bullets: [
          "100lb Gloss Cover",
          "100lb Dull Cover with Matte Finish",
          "80lb Dull Text with Matte Finish"
        ]
      }
    ],
    cost: "Starting at $473 for 250"
  },
  {
    title: "Wall Mount and Rigid Posters"
  }
];
