<template>
  <div id="signage">
    <PrintCarousel  v-if="underMedLarge" :title="title" :images="images" v-on:contact="contact($event)"/>
    <PrintInteractive  v-else 
      :numRows="numRows" :sizeBuffer="sizeBuffer" 
      :title="title" :images="images"
      v-on:contact="contact($event)"
    />
  </div>  
</template>

<script>
import ScreenSize from "@/mixins/screen-size";
import { title, images } from "@/lib/print/showcase/signage";
import PrintCarousel from "@/components/print/below/showcase/Carousel.vue";
import PrintInteractive from "@/components/print/below/showcase/Interactive.vue";

export default {
  mixins: [ScreenSize],
  components: {
    PrintInteractive,
    PrintCarousel
  },
  data() {
    return {
      title,
      images,
      numRows: 3,
      sizeBuffer: 7.5
    };
  },
  methods: {
    contact(spec) {
      this.$emit("contact", spec);
    }
  },
  metaInfo: {
    title: "Graphic Banner Design | Vehicle Wrap Design",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "We provide limitless types of advertising signage. Whether you need small scale in room signage & table top pop-ups or large banners and vehicle wraps, ATH has you covered!"
      }
    ],
    link: [
      {
        vmid: "canonical",
        rel: "canonical",
        href: "https://allthingshospitality.com/print/signage"
      }
    ]
  }
};
</script>
