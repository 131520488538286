<template>
  <div id="factsheets">    
    <PrintCarousel  v-if="underMedLarge" :title="title" :images="images" v-on:contact="contact($event)"/>
    <PrintInteractive v-else :title="title" :images="images" v-on:contact="contact($event)"/>
  </div>  
</template>

<script>
import ScreenSize from "@/mixins/screen-size";
import { title, images } from "@/lib/print/showcase/factsheets";
import PrintCarousel from "@/components/print/below/showcase/Carousel.vue";
import PrintInteractive from "@/components/print/below/showcase/Interactive.vue";

export default {
  mixins: [ScreenSize],
  components: {
    PrintInteractive,
    PrintCarousel
  },
  data() {
    return {
      title,
      images
    };
  },
  methods: {
    contact(spec) {
      this.$emit("contact", spec);
    }
  },
  metaInfo: {
    title: "Hotel Factsheets | High Quality Print Brochures",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Our high quality print brochures are the perfect solution for hotel factsheets, direct mail flyers, and leave behind marketing. Leave a lasting impression your clients will hold onto."
      }
    ],
    link: [
      {
        vmid: "canonical",
        rel: "canonical",
        href: "https://allthingshospitality.com/print/factsheets"
      }
    ]
  }
};
</script>
