<template>
  <router-link
    v-on:click="clicked(button)"
    :to="button.to"
    class="subroute-link"
  >
    <img class="background-img" v-lazy="button.image" :alt="button.image.alt">
    <div class="text-wrapper">
      <div class="pre capitalize standout">
        {{ button.text }}
      </div>
      <div class="post capitalize standout">
        More Info
      </div>    
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    button: {
      type: Object,
      required: true
    }
  },
  computed: {
    isActive() {
      return this.$route.path === this.button.to;
    }
  }
};
</script>

<style scoped>
.subroute-link {
  position: relative;
  flex-basis: 12%;
  overflow: hidden;
}

.router-link-exact-active > .text-wrapper {
  transform: translateY(100%);
  opacity: 0;
}

.subroute-link:hover > .text-wrapper {
  transform: translateX(-50%);
}

.background-img {
  width: 100%;
  display: block;
}

.text-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  display: flex;
  transition: all 0.2s;
  background: rgba(0, 0, 0, 0.2);
}

.pre,
.post {
  font-size: 1.25rem;
  color: white;
  flex-basis: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .subroute-link {
    flex-basis: 30%;
    margin: 0.5rem 0;
  }
}
</style>
