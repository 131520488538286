import { basicSchema } from "@/lib/print/factory";

export default [
  basicSchema(
    "Key Card Insert",
    "Custom keycard insert with guest room information.",
    "Hotel Print Marketing"
  ),
  basicSchema(
    "Hotel Renovation Card",
    "Custom card with renovation information to spread the message!",
    "Hospitality Marketing"
  ),
  basicSchema(
    "Social Media Card",
    "Branded social media card with QR codes and URL information.",
    "Print Sales"
  ),
  basicSchema(
    "Business Card",
    "Unique single person business card with multiple property information.",
    "Hospitality Print Marketing"
  ),
  basicSchema(
    "Branded Business Card",
    "Business card with brand standard graphics and property information.",
    "Hotel Business Card"
  ),
  basicSchema(
    "Branded Key Card Insert",
    "Key card insert with brand specific information & graphics.",
    "Hotel Keycard Inserts"
  ),
  basicSchema(
    "Custom Social Media Card",
    "Business card with social media URL links and QR codes.",
    "Social Media Cards"
  ),
  basicSchema(
    "Restaurant Social Media Card",
    "Restaurant specific social media card to share info with guests.",
    "Restaurant Social Media"
  )
];
