import { imageFactory } from "@/lib/print/factory";
import information from "./information";

const branded = " - Branded";
const factsheet = " - Custom Factsheet";

const meta = [
  { alt: "New Hotel" + branded + factsheet, info: 0 },
  { alt: "Property Features" + branded + factsheet, info: 0 },
  { alt: "Nearby Attractions" + branded + factsheet, info: 0 },
  { alt: "Hotel Layout" + branded + factsheet, info: 0 },
  { alt: "Hotel Information" + branded + factsheet, info: 0 },
  { alt: "Area Restaurants" + branded + factsheet, info: 0 }
];

export const title = "factsheets";
export const images = imageFactory(meta, title, information);
