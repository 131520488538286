<template>
  <div class="print__button__container">
    <PrintButton v-for="button in buttons" 
      :key="button.key" 
      :button="button"
      :isActive="button.active"/>
  </div>
</template>

<script>
import PrintButton from "@/components/print/below/buttons/button/Button.vue";

export default {
  props: {
    buttons: {
      type: Array,
      required: true
    },
    active: Object
  },
  components: {
    PrintButton
  }
};
</script>

<style scoped>
.print__button__container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto 3rem auto;
  justify-content: space-around;
}
</style>
