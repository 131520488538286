<template>
<div id="cards">
  <script v-html="schemaData" type="application/ld+json"></script>
  <PrintCarousel    v-if="underMedLarge" 
    :title="title" :images="images" v-on:contact="contact($event)"
  />
  <PrintInteractive v-else 
    :numRows="numRows" :sizeBuffer="sizeBuffer"  :title="title" 
    :images="images" v-on:contact="contact($event)"
  />
</div>
  
</template>

<script>
import ScreenSize from "@/mixins/screen-size";
import { title, images, schemaData } from "@/lib/print/showcase/cards";
import PrintCarousel from "@/components/print/below/showcase/Carousel.vue";
import PrintInteractive from "@/components/print/below/showcase/Interactive.vue";

export default {
  mixins: [ScreenSize],
  components: {
    PrintInteractive,
    PrintCarousel
  },
  data() {
    return {
      title,
      images,
      schemaData,
      numRows: 3,
      sizeBuffer: 7.5
    };
  },
  methods: {
    contact(spec) {
      this.$emit("contact", spec);
    }
  },
  metaInfo: {
    title: "Business Cards | Key Card Inserts",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "We offer high quality business cards, key card inserts, and social media cards. Browse our professional design, copywriting, and print services today."
      }
    ],
    link: [
      {
        vmid: "canonical",
        rel: "canonical",
        href: "https://allthingshospitality.com/print/cards"
      }
    ]
  }
};
</script>
