<template>
  <PrintCarousel  v-if="underMedLarge" :title="title" :images="images" v-on:contact="contact($event)"/>
    <PrintInteractive v-else :title="title" :images="images" v-on:contact="contact($event)"/>
</template>

<script>
import ScreenSize from "@/mixins/screen-size";
import { title, images } from "@/lib/print/showcase/food-and-bev";
import PrintCarousel from "@/components/print/below/showcase/Carousel.vue";
import PrintInteractive from "@/components/print/below/showcase/Interactive.vue";

export default {
  mixins: [ScreenSize],
  data() {
    return { title, images };
  },
  components: { PrintCarousel, PrintInteractive },
  methods: {
    contact(spec) {
      this.$emit("contact", spec);
    }
  },
  metaInfo: {
    title: "Restaurant Menu Design | Restaurant Advertising",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Explore our professional menu design services and restaurant advertising options. Wall mounted and rigid posters empower you to advertise your hotel restaurant anywhere on your property."
      }
    ],
    link: [
      {
        vmid: "canonical",
        rel: "canonical",
        href: "https://allthingshospitality.com/print/food-and-beverage"
      }
    ]
  }
};
</script>
