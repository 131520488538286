export default [
  {
    title: `2" x 3.5" Key Card Insert`,
    subtitle: null,
    lists: [
      {
        title: "Custom key card insert design:",
        bullets: [
          "Promote in-house offers, social connections and guest reviews",
          "Includes professional design, copywriting and print"
        ]
      },
      {
        title: "Premium stock upgrades options:",
        bullets: [
          "16 pt Dull Cover Matte Finish",
          "24pt Ultimate Trifecta Green Triple Layered",
          "38 pt Ultimate Trifecta Black or Red Triple Layered"
        ]
      }
    ],
    cost: "Prices Starting at $190 for 1,000"
  },
  {
    title: "Renovation Announcement",
    subtitle: null,
    lists: [
      {
        title: "Custom Design",
        bullets: [
          "Showcase recent renovations",
          "Includes professional design, copywriting, and print"
        ]
      }
    ],
    cost: "Prices starting at $190 for 1,000"
  },
  {
    title: `2" x 3.5" Social Media Card`,
    subtitle: null,
    lists: [
      {
        title: "Custom social media cards:",
        bullets: [
          "Promote social connections and guest reviews",
          "Includes professional design, copywriting and print"
        ],
        cost: "Prices starting at $99 for 1,000"
      }
    ]
  },
  {
    title: `2" x 3.5" Business Card`,
    subtitle: null,
    lists: [
      {
        title: "Custom business card design:",
        bullets: [
          "Multi-property and multi-brand designs available",
          "Rounded corners, die cut and single-sided available",
          "Includes professional design, copywriting and print",
          "Multiple paper stocks and spot high-gloss UV coating available"
        ]
      },
      {
        title: "Premium stock upgrade options:",
        bullets: [
          "16pt Dull Cover Matte Finish",
          "24pt Ultimate Trifecta Green Triple Layered",
          "38pt Ultimate Trifecta Black or Red Triple Layered"
        ]
      }
    ],
    cost: "Prices starting at $217 for 1,000"
  }
];
