export default [
  {
    title: `4" x 9" Rack Card`,
    subtitle: null,
    lists: [
      {
        title: "Includes:",
        bullets: [
          "Full color, two-sided, with bleed",
          "Professional design and copywriting",
          "14pt cardstock standard",
          "Multi-property and multi-brand designs available"
        ]
      },
      {
        title: "Premium stock upgrade options",
        bullets: [
          "14pt Premium Uncoated Cover",
          "16pt Dull Cover Matte Finish",
          "24pt Ultimate Trifecta Green Triple Layered",
          "38pt Ultimate Trifecta Black or Red Triple Layered"
        ]
      }
    ],
    cost: "Starting at $372 for 250"
  }
];
